<template>
    <div>
        <pageHeader :pagename="'创建新的企业人员账户'" />

        <el-row>
            <el-col :span="24">
                <el-form>
                    <el-form-item label="登录邮箱">
                        <el-input
                            placeholder="Email"
                            v-model="formdata.email"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="登录手机号">
                        <el-input
                            placeholder="Phone"
                            v-model="formdata.phone"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="账户名">
                        <el-input
                            placeholder="Name"
                            v-model="formdata.name"
                        ></el-input>
                    </el-form-item>

                    <el-divider></el-divider>

                    <el-form-item label="登录密码">
                        <el-input
                            placeholder="请输入密码"
                            v-model="formdata.password"
                            show-password
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="重复输入一遍登录密码">
                        <el-input
                            placeholder="请输入密码"
                            v-model="formdata.re_password"
                            show-password
                        ></el-input>
                    </el-form-item>

                    <el-divider></el-divider>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()"
                            >立即创建</el-button
                        >
                        <el-button>取消</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { personnelAccountCreate } from '@/api/manage/personnel_management.js';

export default {
    name: 'start',
    data() {
        return {
            formdata: {
                email: null,
                password: null,
                re_password: null,
                phone: null,
                name: null
            },
            variate: {},
            status: {},
            doc: {}
        };
    },
    components: {},
    methods: {
        onSubmit() {
            this.$http(personnelAccountCreate(this.formdata), (res) => {
                console.log(res);
                this.$router.push({
                  name:'personnelManagement_AccountManage'
                })
            });
        }
    }
};
</script>

<style type="scss"></style>
